<template>
  <v-container class="test-form">
    <ValidationObserver v-slot="{ handleSubmit }">
      <v-form
        @submit.prevent="handleSubmit(saveData)"
        method="post"
        id="check-login-form"
      >
        <v-row>
          <v-col cols="2" class="h4 font-weight-black">
            {{ language.user_full_name }}
            <span class="red--text font-weight-normal">(*)</span>
          </v-col>
          <v-col cols="10">
            <ValidationProvider rules="required" v-slot="{ errors }">
              <HnrInput
                v-model="detail.user_full_name"
                :text="detail.user_full_name"
              ></HnrInput>
              <span class="validate-error text-danger text-danger">
                {{ errors[0] }}
              </span>
            </ValidationProvider>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="2" class="h4 font-weight-black">
            {{ language.title }}
            <span class="red--text font-weight-normal">(*)</span>
          </v-col>
          <v-col cols="10">
            <ValidationProvider rules="required" v-slot="{ errors }">
              <HnrInput v-model="detail.title" :text="detail.title"></HnrInput>
              <span class="validate-error text-danger text-danger">
                {{ errors[0] }}
              </span>
            </ValidationProvider>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="2" class="h4 font-weight-black">
            {{ language.slug }}
          </v-col>
          <v-col cols="10">
            <ValidationProvider rules="required" v-slot="{ errors }">
              <HnrInput v-model="detail.slug" :text="detail.slug"></HnrInput>
              <span class="validate-error text-danger text-danger">
                {{ errors[0] }}
              </span>
            </ValidationProvider>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="2" class="h4 font-weight-black">
            {{ language.content }}
            <span class="red--text font-weight-normal">(*)</span>
            <div class="text-h6">
              {{ language.imageDisplay }}
            </div>
          </v-col>
          <v-col cols="10">
            <CkContent
              :content="detail.content"
              @getData="getContent"
            ></CkContent>
            <span class="validate-error text-danger text-danger">
              {{ contentErrorMessage }}
            </span>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="2" class="h4 font-weight-black">
            {{ language.categories }}
          </v-col>
          <v-col cols="10">
            <v-select
              v-if="categoriesBtn"
              :items="categoriesList"
              dense
              item-text="title"
              v-model="categories"
              :placeholder="detail.categories.title"
              outlined
              return-object
              color="primary"
            ></v-select>
            <v-select
              v-else
              :items="categoriesList"
              class="shrink money-box"
              dense
              item-text="title"
              v-model="categories"
              outlined
              return-object
              color="primary"
            ></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="2" class="h4 font-weight-black">
            {{ language.seoTitle }}
            <span class="red--text font-weight-normal">(*)</span>
          </v-col>
          <v-col cols="10">
            <ValidationProvider rules="required" v-slot="{ errors }">
              <HnrInput
                v-model="detail.seo_title"
                :text="detail.seo_title"
              ></HnrInput>
              <span class="validate-error text-danger text-danger">
                {{ errors[0] }}
              </span>
            </ValidationProvider>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="2" class="h4 font-weight-black">
            {{ language.seoDescription }}
            <span class="red--text font-weight-normal">(*)</span>
          </v-col>
          <v-col cols="10">
            <ValidationProvider rules="required" v-slot="{ errors }">
              <v-textarea
                outlined
                name="input-7-4"
                v-model="detail.seo_description"
              ></v-textarea>
              <span class="validate-error text-danger text-danger">
                {{ errors[0] }}
              </span>
            </ValidationProvider>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="2" class="h4 font-weight-black">
            {{ language.seoKey }}
            <span class="red--text font-weight-normal">(*)</span>
          </v-col>
          <v-col cols="10">
            <ValidationProvider rules="required" v-slot="{ errors }">
              <HnrInput
                v-model="detail.seo_key"
                :text="detail.seo_key"
              ></HnrInput>
              <span class="validate-error text-danger text-danger">
                {{ errors[0] }}
              </span>
            </ValidationProvider>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="2" class="h4 font-weight-black">
            {{ language.image }}
            <span class="red--text font-weight-normal">(*)</span>
            <div class="text-h6">
              {{ language.imageDisplay }}
            </div>
          </v-col>
          <v-col cols="10">
            <v-file-input
              class="pl-1"
              prepend-icon="mdi-camera"
              v-model="image"
              label="Upload image"
              @change="onChangeFileUpload"
            ></v-file-input>
            <v-img
              v-if="cardImage"
              max-height="350"
              max-width="500"
              :src="cardImage"
            ></v-img>
            <span class="red--text text-h6">{{ img_error_msg }}</span>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12 text-center">
            <span class="validate-error text-danger text-danger float-left">
              (*) : Mục cần nhập
            </span>
            <HnrButton
              :text="'Lưu'"
              :color="'primary'"
              :rounded="true"
              :outlined="true"
              :width="400"
              type="submit"
              form="check-login-form"
            ></HnrButton>
          </v-col>
        </v-row>
      </v-form>
    </ValidationObserver>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import CkContent from "@/components/ck-content";
import {
  GET_DETAIL_NEWS,
  UPDATE_NEW,
  UPLOAD_FLASHCARD_IMAGE,
  GET_LIST_CATEGORIES
} from "@/store/new.module";
import HnrInput from "@/components/elements/hnr-input";
import HnrButton from "@/components/elements/hnr-button";

export default {
  name: "form",
  //form to update
  data: () => {
    return {
      alerts: true,
      language: {
        title: "Tên bài viết",
        slug: "slug",
        content: "Nội dung bài viết",
        image: "Ảnh tiêu đề",
        seoTitle: "SEO Title",
        seoDescription: "SEO Description",
        seoKey: "SEO Keyword",
        user_full_name: "Tên tác giả",
        imageDisplay: "(900px x 500px)",
        picture: "Ảnh (764px x 638px)",
        categories: "Mục lục"
      },
      file: null,
      image: {},
      cardImage: "",
      categories: "",
      contentErrorMessage: "",
      categoriesBtn: true,
      img_error_msg: ""
    };
  },

  created() {
    this.checkifNewsCategoriesExist();
    var payload = {
      news_id: this.$route.query.news_id
    }
    this.$store.dispatch(GET_DETAIL_NEWS, payload);
    this.$store.dispatch(GET_LIST_CATEGORIES, 0);
  },

  components: {
    CkContent,
    HnrInput,
    HnrButton
  },

  computed: {
    ...mapGetters({
      categoriesList: "getListCategories",
      detail: "getDetailNew"
    })
  },

  watch: {
    detail: function(val) {
      val.slug = this.sanitizeTitle(val.title);
      this.cardImage = val.image_url;
    },
    "detail.title": function(val) {
      this.detail.slug = this.sanitizeTitle(val);
    },
    categories: function(val) {
      this.detail.categories_id = val.id;
    },
    "detail.content": function(val) {
      if (val.length > 0) {
        this.contentErrorMessage = "";
      } else {
        this.contentErrorMessage = "Vui lòng nhập nội dung của bài viết";
      }
    }
  },

  methods: {
    async onChangeFileUpload() {
      this.alerts = false;
      this.cardImage = URL.createObjectURL(this.image);
    },
    getData() {
      return localStorage.getItem("data");
    },

    sanitizeTitle(title) {
      // Change to lower case
      var str = title.toLowerCase();
      str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
      str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
      str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
      str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
      str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
      str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
      str = str.replace(/đ/g, "d");
      // Some system encode vietnamese combining accent as individual utf-8 characters
      str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, ""); // Huyền sắc hỏi ngã nặng
      str = str.replace(/\u02C6|\u0306|\u031B/g, ""); // Â, Ê, Ă, Ơ, Ư
      // Trim the last whitespace
      str = str.replace(/\s*$/g, "");
      // Change whitespace to "-"
      str = str.replace(/\s+/g, "-");

      return str;
    },

    getContent(value) {
      this.detail.content = value;
    },

    getSeoDescription(value) {
      this.detail.seo_description = value;
    },
    checkifNewsCategoriesExist() {
      if (!this.detail.categories_id) {
        this.categoriesBtn = false;
        console.log("not have");
      } else {
        this.categoriesBtn = true;
        console.log("have");
      }
    },
    async saveData() {
      if (this.detail.content.length == 0) {
        this.contentErrorMessage = "Vui lòng nhập nội dung của bài viết";
      } else {
        this.contentErrorMessage = "";
        console.log(this.image.type);
        if (this.image.type === undefined) {
          this.updateNews();
        } else {
          if (
            this.image.type === "image/jpeg" ||
            this.image.type === "image/png" ||
            this.image.type === "image/webp"
          ) {
            this.updateNews();
          } else {
            this.img_error_msg = "File nhập vào phải là một hình ảnh";
          }
        }
      }
    },
    async updateNews() {
      if (this.image) {
        let formData = new FormData();
        formData.append("file", this.image);
        await this.$store
            .dispatch(UPLOAD_FLASHCARD_IMAGE, formData)
            .then(data => {
              if (data.data) {
                this.file = data.data.data;
              }
            })
            .catch(err => {
              console.log("err", err);
            });
      }
      if (!this.categoriesBtn) {
        this.detail.categories_id = this.categories.id;
      }
      var data = {
        id: this.$route.query.news_id,
        title: this.detail.title,
        content: this.detail.content,
        slug: this.sanitizeTitle(this.detail.title),
        user_full_name: this.detail.user_full_name,
        seo_key: this.detail.seo_key,
        seo_description: this.detail.seo_description,
        seo_title: this.detail.seo_title,
        check: this.detail.check,
        date: this.detail.date,
        image_url: this.file?this.file:this.cardImage,
        categories_id: this.detail.categories_id,
        top: this.detail.top,
        status: this.detail.status
      };

      await this.$store.dispatch(UPDATE_NEW, data);
      this.$router.push({
        path: "/new/list"
      });
    },
  }
};
</script>

<style></style>
